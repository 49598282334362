.login-input Input{
  width: 80%;
  font-size: 30px;
}

.numbers-panel button{
  margin: 5px;
  width: 30%;
  padding-top: 20%;
  font-size: 30px;
  position: relative;
}

.numbers-panel span {
  position: absolute;
  top: 35%;
  left: 0;
  bottom: 0;
  right: 0;
}

.login-panel{
  width: 80%;
  margin: auto;
}

@media screen and (min-width: 700px) {
  .login-panel{
    width: 500px;
  }
}
