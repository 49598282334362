* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #ECF0F5;
  font-size: 17px;
  color: #444444;
  text-align: center;
  line-height: 1.5;
  background-image: url('../../images/bottles.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#background-image {
  background-color: white;
  opacity:0.9;
  top: 0px;
  left: 0px;
  position:fixed;
  height:100%;
  width:100%;
  z-index:-1;
}

.PictureButton {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

nav,
footer {
  background-color:	#009fff;
  height: 50px;
  font-style: italic;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 5px 5px 3px grey;
}

fieldset {
  border: 1px solid gray;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

.content-with-nav {
  margin-top: 65px;
}

button {
    margin: 15px auto 0px auto;
    padding: 9px 32px;
    font-size: 17px;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: black;
    border: none;
    border-radius: 8px;
    box-shadow: 0 3px #6C757D;
    margin-left: 10px;
    margin-right: 10px;
}

button:active {
    box-shadow: 0 4px #666666;
    transform: translateY(4px);
}

.blue {
  background-color: #167AF6;
}

.blue:hover, .blue:focus {
    background-color: #1264C9;
}

.green {
  background-color: #4CA846;
}

.green:hover, .green:focus {
    background-color: #3B8236;
}

.orange {
  background-color: #F8C246;
}

.orange:hover, .orange:focus {
    background-color: #B89034;
}

.red {
  background-color: #DC3545;
}

.red:hover, .red:focus {
    background-color: #A02632;
}

.gray {
  background-color: #D5D5D5;
  border: 1px solid #ACAC9D;
  color: black
}

.gray:hover, .gray:focus {
  background-color: #ACAC9D;
}

.errorMsg {
  margin-top: 5px;
  padding: 5px;
  color: black;
  background-color: #DC3545;
  border: 1px solid red;
  border-radius: 3px;
}

.msgMsg {
  margin-top: 5px;
  padding: 5px;
  color: black;
  background-color: #167AF6;
  border: 1px solid blue;
  border-radius: 3px;
}

.border-gray {
  border: 1px solid #ACAC9D;
}

section {
  margin: 15px;
  padding: 10px;
  border: 1px solid #DBDBEA;
  border-radius: 7px;
  background-color: #FFFFFFCC;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

section.title-section {
  /*background-color: #48ABEF*/
  background-image: url('../../images/bottles.jpg');
  color: white;
  text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 25px black;
}

.title-section p, .title-subsection {
  color: black;
  text-shadow: none;
  text-align: left;
}

section fieldset{
  border: 1px solid #DBDBEA;
  background-color: white;
  border-radius: 7px;
  display:table;
  background-color: green;
}

section .fieldset{
  border: 1px solid gray;
  margin: auto;
  padding: 0.35em 0.625em 0.75em;
  width: 100%;
  border: 1px solid #DBDBEA;
  background-color: white;
  border-radius: 7px;
  display:flex;
}

input, textarea, select {
  font-size: 16px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.item-description div{
  display: inline-block;
  width: 45%;
}

.item-description-p div{
  display: inline-block;
  width: 30%;
}

.div-picture {
  display: inline;
  width: 30%;
}

.div-content {
  width: 60%;
  display: inline-block;
}

.div-picture img{
  max-width:100px;
  height:auto;
}

.item-description span, .item-description-p span{
  color: #666666;
}

.title-box {
  font-size: 17px;
  font-weight: bold;
}

ul {
  text-align: left;
}

.imageb {
  position: relative;
  width: 50px;
  padding: 3px;
  top: -200px;
  box-shadow: none;
  background-color: none;
  border: 1px solid gray;
}

.back-image {
  left: -80px;
}

.next-image {
  right: -80px;
}

.imageb img {
  max-width: 50px;
  max-height: 50px;
}

div.polaroid {
  margin: 0 auto;
  padding-top: 9px;
  width: 220px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

div.container {
  padding-bottom: 1px;
}

.main-title {
  top: -15px;
  left: 8%;
  position: relative;
  text-align: left;
  color: black;
}

.main-button {
  top: -91px;
  right: -35%;
  position: relative;
}

@media screen and (min-width: 500px) {
  section .fieldset {
    width: 80%;
    margin: auto;
  }
}

@media screen and (min-width: 850px) {
  .elements-form {
    width: 90%;
    display: table;
    clear: both;
    margin: 0 auto;
  }

  .elements-form .fieldset {
    float: left;
    width: 50%;
    margin: auto;
  }
}
