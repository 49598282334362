
.back-nav {
  top: 15px;
  left: 0;
  width: 20%;
  position: fixed;
}

.name-centered {
  top: -15px;
  position: relative;
  text-align: center;
  color: black;
}

a{
  color: black;
}

.loading {
  background-color: #555555BB;
  color: white;
  border-radius: 5px;
  padding: 30px;
  position: absolute;
  right: 5px;
  top: 60px;
}
