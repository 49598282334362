section {

}

.main-item {
  width: 40%;
  display: inline-block;
  margin: 10px;
}

.menu-item {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  border: 1px solid #DBDBEA;
  border-radius: 7px;
  text-align: center;
}

.main-item img {
  width: 70%;
}


@media screen and (min-width: 570px) {
  .main-item{
    width: 28%;
  }

}

@media screen and (min-width: 1000px) {
  .main-item{
    width: 20%;
  }

}
