.item-list {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 800px) {
  .item-list{
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
